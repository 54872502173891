import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const workflowsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "workflowsApi",
    baseUrl: "/api/workflows",
  }),
  reducerPath: "workflowsApi",
  tagTypes: ["Workflow", "WorkflowStep"],
  endpoints: (builder) => ({
    getWorkflowById: builder.query<any, { id: string; include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Workflow" }],
    }),
    getWorkflowStepById: builder.query<
      any,
      { workflowId: string; stepId: string }
    >({
      query: (args) => {
        return `/${args.workflowId}/steps/${args.stepId}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "WorkflowStep" }],
    }),
    updateWorkflow: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    createWorkflowStep: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/steps`,
        method: "POST",
        body: data,
      }),
    }),
    updateWorkflowStep: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/steps/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteWorkflowStep: builder.mutation<any, { id: string; stepId: string }>({
      query: ({ id, stepId }) => ({
        url: `/${id}/steps/${stepId}`,
        method: "DELETE",
      }),
    }),
    mergeWorkflow: builder.mutation<
      any,
      { id: string; workflowTemplateId: any }
    >({
      query: ({ id, workflowTemplateId }) => ({
        url: `/${id}/explode`,
        method: "POST",
        body: { workflowTemplateId },
      }),
    }),
  }),
});

export const {
  useGetWorkflowByIdQuery,
  useGetWorkflowStepByIdQuery,
  useUpdateWorkflowMutation,
  useCreateWorkflowStepMutation,
  useUpdateWorkflowStepMutation,
  useDeleteWorkflowStepMutation,
  useMergeWorkflowMutation,
} = workflowsApi;
