import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const eventTemplatesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "eventTemplatesApi",
    baseUrl: "/api/event-templates",
  }),
  reducerPath: "eventTemplatesApi",
  tagTypes: ["EventTemplate"],
  endpoints: (builder) => ({
    getEventTemplateById: builder.query<any, { id: string; include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "EventTemplate" }],
    }),
    updateEventTemplate: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteEventTemplate: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetEventTemplateByIdQuery,
  useUpdateEventTemplateMutation,
  useDeleteEventTemplateMutation,
} = eventTemplatesApi;
