import { format, isThisYear } from "date-fns";

export const isNullOrUndefined = (value: any): boolean => {
  return value === null || value === undefined;
};

export const getFirstInitial = (name: string): string => {
  return name.trim()[0].toUpperCase();
};

export const getInitials = (name: string) => {
  const nameParts = name.split(" ");
  const initials = nameParts
    .slice(0, 2)
    .map((part) => part[0])
    .join("");
  return initials.toUpperCase();
};

export const transformToSentenceCase = (input: string) => {
  const result = input
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase());

  return result;
};

export const camelCaseToSentenceCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, " $1").toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const convertUnderscoreToSentenceCase = (str = "") => {
  const words = str.toLowerCase().split("_");

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(" ");
};

export const convertToTitleCase = (str: string) => {
  const exceptions = ["a"]; // List of words to not capitalize
  return str
    .split(" ")
    .map((word, index) => {
      // Always capitalize the first word
      if (index === 0 || !exceptions.includes(word.toLowerCase())) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word; // Keep the exception word as is
    })
    .join(" ");
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatAddress = (address: any) => {
  if (!address) return "";
  return `${address?.addressLine1}, ${address?.city}, ${address?.state} ${address?.postalCode}`;
};

export const formatPhoneNumber = (phone: string) => {
  if (!phone) return "";

  const cleaned = phone.replace(/\D/g, "");

  if (cleaned.length > 10) {
    const countryCodeLength = cleaned.length - 10;
    return `+${cleaned.slice(0, countryCodeLength)} (${cleaned.slice(
      countryCodeLength,
      countryCodeLength + 3
    )}) ${cleaned.slice(
      countryCodeLength + 3,
      countryCodeLength + 6
    )}-${cleaned.slice(countryCodeLength + 6)}`;
  }

  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
};

export const parsePhoneNumber = (phone: string) => {
  if (!phone) return "";

  const cleaned = phone.replace(/\D/g, "");

  return cleaned.length === 10 ? `+1${cleaned}` : `+${cleaned}`;
};

export const formatDateTime = (date: string | Date) => {
  return format(date, "MM/dd/yyyy 'at' h:mmaaa");
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  if (isThisYear(date)) {
    return format(date, "MMM d");
  }
  return format(date, "MMM d, yyyy");
};

// To format time as '4d', '2h', '5m', etc.
export const timeAgo = (dateTime: string) => {
  const now = new Date();
  const past = new Date(dateTime);
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;

  if (diffInSeconds < secondsInMinute) {
    return `${diffInSeconds}s`;
  } else if (diffInSeconds < secondsInHour) {
    return `${Math.floor(diffInSeconds / secondsInMinute)}m`;
  } else if (diffInSeconds < secondsInDay) {
    return `${Math.floor(diffInSeconds / secondsInHour)}h`;
  } else if (diffInSeconds < secondsInWeek) {
    return `${Math.floor(diffInSeconds / secondsInDay)}d`;
  } else {
    return `${Math.floor(diffInSeconds / secondsInWeek)}w`;
  }
};

export const isObjectEmpty = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const toTitleCase = (str: string) =>
  str
    ?.replace(/_/g, " ")
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");

// utilities/calendarLinks.ts
export const generateCalendarLinks = (event: any) => {
  const { name, description, startDateTime, endDateTime } = event;

  // Format dates to UTC strings
  const start = new Date(startDateTime).toISOString();
  const end = new Date(endDateTime).toISOString();

  // Google Calendar link
  const googleLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    name
  )}&dates=${start.replace(/-|:|\.\d+/g, "")}/${end.replace(
    /-|:|\.\d+/g,
    ""
  )}&details=${encodeURIComponent(description)}`;

  // Outlook Calendar link
  const outlookLink = `https://outlook.live.com/owa/?path=/calendar/action/compose&subject=${encodeURIComponent(
    name
  )}&body=${encodeURIComponent(description)}&startdt=${start}&enddt=${end}`;

  // iCal link (download .ics file)
  const icsData = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${name}
DESCRIPTION:${description}
DTSTART:${start.replace(/-|:|\.\d+/g, "")}
DTEND:${end.replace(/-|:|\.\d+/g, "")}
LOCATION:${location || ""}
END:VEVENT
END:VCALENDAR`;

  const icsLink = `data:text/calendar;charset=utf8,${encodeURIComponent(
    icsData
  )}`;

  return {
    google: googleLink,
    outlook: outlookLink,
    ical: icsLink,
  };
};
