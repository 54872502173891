import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const documentTemplatesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "documentTemplatesApi",
    baseUrl: "/api/document-templates",
  }),
  reducerPath: "documentTemplatesApi",
  tagTypes: ["Document"],
  endpoints: (builder) => ({
    getDocumentTemplates: builder.query<
      any,
      {
        include?: string;
        search?: string;
        nextId?: string;
        limit?: number;
        shareLevel?: string;
      }
    >({
      query: (args) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        // Append parameters if they exist
        if (args.search) queryParams.append("query", args.search);
        if (args.include) queryParams.append("include", args.include);
        if (args.limit) queryParams.append("limit", args.limit.toString());
        if (args.nextId) queryParams.append("nextId", args.nextId);
        if (args.shareLevel) queryParams.append("shareLevel", args.shareLevel);
        return `?${queryParams.toString()}`;
      },
      keepUnusedDataFor: 0,
    }),
    getDocumentTemplatesWithFieldsById: builder.query<
      any,
      { id?: string; include?: string }
    >({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}/fields${queryParams}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Document" }],
    }),
    getDocumentTemplateHistory: builder.query<any, { id: string }>({
      query: (args) => `/${args.id}/history`,
      keepUnusedDataFor: 0,
    }),
    getDocumentTemplateHistoryById: builder.query<
      any,
      { id: string; historyId?: string }
    >({
      query: (args) => `/${args.id}/history/${args.historyId}`,
      keepUnusedDataFor: 0,
    }),
    updateDocumentTemplateById: builder.mutation<
      any,
      { id: string; data: any }
    >({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    patchDocumentTemplateById: builder.mutation<any, { id: string; data: any }>(
      {
        query: ({ id, data }) => ({
          url: `/${id}`,
          method: "PATCH",
          body: data,
        }),
      }
    ),
    createDocumentTemplate: builder.mutation<
      any,
      {
        type: string;
        name: string;
        folderName?: string;
        order?: number;
        version?: number;
      }
    >({
      query: ({ type, name, folderName, order }) => ({
        url: `/`,
        method: "POST",
        body: {
          name,
          type,
          folderName,
          order: order || 0, // TODO: what order do we put for templates not on a workflow?
          version: 0, // required by backend, always going to be 0 on first create
        },
      }),
    }),
    deleteDocumentTemplate: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetDocumentTemplatesQuery,
  useGetDocumentTemplatesWithFieldsByIdQuery,
  useGetDocumentTemplateHistoryQuery,
  useGetDocumentTemplateHistoryByIdQuery,
  useUpdateDocumentTemplateByIdMutation,
  usePatchDocumentTemplateByIdMutation,
  useCreateDocumentTemplateMutation,
  useDeleteDocumentTemplateMutation,
} = documentTemplatesApi;
