import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const eventsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "eventsApi",
    baseUrl: "/api/events",
  }),
  reducerPath: "eventsApi",
  tagTypes: ["Events"],
  endpoints: (builder) => ({
    getEvents: builder.query<
      any,
      { id?: string; include?: string; limit?: number }
    >({
      query: (args) => {
        const params = new URLSearchParams();

        if (args.include) {
          params.append("include", args.include);
        }

        if (args.limit) {
          params.append("limit", args.limit?.toString());
        }

        return `${args.id ? `/${args.id}` : ""}?${params.toString()}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Events" }],
    }),
    createEvent: builder.mutation<any, { data: any }>({
      query: ({ data }) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
    }),
    updateEvent: builder.mutation<any, { id: string; status: string }>({
      query: ({ id, status }) => ({
        url: `/${id}`,
        method: "PUT",
        body: { status },
      }),
    }),
    addEventParticipants: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/participants`,
        method: "POST",
        body: data,
      }),
    }),
    removeEventParticipants: builder.mutation<
      any,
      { id: string; personId: string }
    >({
      query: ({ id, personId }) => ({
        url: `/${id}/participants/${personId}`,
        method: "DELETE",
      }),
    }),
    addEventProperties: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}/properties`,
        method: "POST",
        body: data,
      }),
    }),
    removeEventProperties: builder.mutation<
      any,
      { id: string; propertyId: string }
    >({
      query: ({ id, propertyId }) => ({
        url: `/${id}/properties/${propertyId}`,
        method: "DELETE",
      }),
    }),
    deleteEvent: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetEventsQuery,
  useLazyGetEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useAddEventParticipantsMutation,
  useRemoveEventParticipantsMutation,
  useAddEventPropertiesMutation,
  useRemoveEventPropertiesMutation,
  useDeleteEventMutation,
} = eventsApi;
