import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const dealsApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "dealsApi",
    baseUrl: "/api/deals",
  }),
  reducerPath: "dealsApi",
  tagTypes: ["Deals", "Fields"],
  endpoints: (builder) => ({
    getDeals: builder.query<
      any,
      {
        include?: string;
        search?: string;
        nextId?: string;
        limit?: number;
        status?: string;
      }
    >({
      query: (args) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        // Append parameters if they exist
        if (args.search) queryParams.append("query", args.search);
        if (args.include) queryParams.append("include", args.include);
        if (args.limit) queryParams.append("limit", args.limit.toString());
        if (args.nextId) queryParams.append("nextId", args.nextId);
        if (args.status) queryParams.append("status", args.status);
        return `?${queryParams.toString()}`;
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Deals" }],
    }),
    getDealById: builder.query<any, { id?: string; include: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      providesTags: ({ id }) => [{ type: "Deals", id }],
    }),
    createDeal: builder.mutation<any, any>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Deals" }],
    }),
    deleteDeal: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Deals" }],
    }),
    getMissingFieldsForDeal: builder.query<
      any,
      { id?: string; status?: string }
    >({
      query: (args) =>
        `/${args.id}/documents/fields?status=${
          args.status ? args.status : "UNRESOLVED"
        }`,
      keepUnusedDataFor: 0,
      providesTags: [{ type: "Fields" }],
    }),
  }),
});

export const {
  useGetDealsQuery,
  useGetDealByIdQuery,
  useCreateDealMutation,
  useDeleteDealMutation,
  useGetMissingFieldsForDealQuery,
} = dealsApi;
