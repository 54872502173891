import { Provider } from "react-redux";
import { Routes } from "@/Routes";
import { SentryWrapper } from "@/hooks/sentry";
import { store } from "@/store";
import { Toaster } from "@/components/ui/sonner";

export default function App() {
  return (
    <SentryWrapper>
      <Provider store={store}>
        <Routes />
      </Provider>
      <Toaster position="bottom-right" />
    </SentryWrapper>
  );
}
