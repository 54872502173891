import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseApiWithAuth } from "@/utilities/reduxToolkit";

export const propertiesApi = createApi({
  ...createBaseApiWithAuth({
    reducerPath: "propertiesApi",
    baseUrl: "/api/properties",
  }),
  reducerPath: "propertiesApi",
  tagTypes: ["Properties"],
  endpoints: (builder) => ({
    getProperties: builder.query<
      any,
      { include?: string; search?: string; nextId?: string; limit?: number }
    >({
      query: (args) => {
        // Build query parameters dynamically
        const queryParams = new URLSearchParams();
        // Append parameters if they exist
        if (args.search) queryParams.append("query", args.search);
        if (args.include) queryParams.append("include", args.include);
        if (args.limit) queryParams.append("limit", args.limit.toString());
        if (args.nextId) queryParams.append("nextId", args.nextId);
        return `?${queryParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result?.data?.data?.map(
                ({ id }: any) => ({ type: "Properties", id } as const)
              ),
              "Properties",
            ]
          : ["Properties"],
    }),
    getPropertyById: builder.query<any, { id?: string; include?: string }>({
      query: (args) => {
        const queryParams = args.include ? `?include=${args.include}` : "";
        return `/${args.id}${queryParams}`;
      },
      providesTags: ({ id }) => [{ type: "Properties", id }],
    }),
    createProperty: builder.mutation<any, any>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Properties" }],
    }),
    deleteProperty: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        // Optimistically update the cache
        const patchResult = dispatch(
          propertiesApi.util.updateQueryData(
            "getProperties",
            { include: "propertyImages,propertyData" },
            (draft: any) => {
              return {
                ...draft,
                data: draft.data.filter((property: any) => property.id !== id),
              };
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "Properties", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useLazyGetPropertiesQuery,
  useGetPropertyByIdQuery,
  useCreatePropertyMutation,
  useDeletePropertyMutation,
} = propertiesApi;
